import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MembershipRevenueBreakDownApi = (data) => {
    return API.post(`${URL.GET_ALL_REVENUE_BREAK_DOWN_LIST}`, data.body, { params: data?.params })
}
export const MemberListRevenueBreakDownApi = (data) => {
    return API.post(`${URL.GET_ALL_MEMBER_LIST_REVENUE_BREAK_DOWN_LIST}`, data.body, {
        params: data?.params
    })
}

export const PlanListRevenueBreakDownApi = (data) => {
    return API.post(`${URL.PLAN_LIST_REVENUE_BREAK_DOWN_LIST}`, data.body, {
        params: data?.params
    })
}

export const RevenueBreakDownGrandTotalApi = (data) =>
    API.get(`${URL.REVENUE_BREAK_DOWN_GRAND_TOTAL}`, data?.params)