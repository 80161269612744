import axios from 'axios'
import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubListApi = (data) => API.get(`${URL.CLUB_LOAD_URL}`, data)

export const MembershipTypeListApi = (data) => API.get(`${URL.MEMBERSHIP_LIST_TYPE_LIST}`, data)

export const MemberUserListApi = (data) => API.get(`${URL.MEMBER_USER_LIST}`, data)

export const MembershipPlansByIdForMemberApi = (Id) =>
    API.get(`${URL.MEMBERSHIP_PLANS_ONE_MEMBER}/${Id}`)

export const MemberShipListApi = (data) => {
    // `${URL.MEMBERSHIP_LIST}?_limit=${data?.params?._limit}&_page=${data?.params?._page}&_sort=${data?.params?._sort}&_order=${data?.params?._order}`
    return API.post(`${URL.MEMBERSHIP_LIST}`, data.bodyData, {
        params: data?.params
    })
}
export const SubMemberMemberShipListApi = (data) => {
    // `${URL.MEMBERSHIP_LIST}?_limit=${data?.params?._limit}&_page=${data?.params?._page}&_sort=${data?.params?._sort}&_order=${data?.params?._order}`
    return API.post(`${URL.SUB_MEMBER_MEMBERSHIP_LIST}`, data?.bodyData)
}
export const MemberShipListOldApi = (data) => {
    // `${URL.MEMBERSHIP_LIST}?_limit=${data?.params?._limit}&_page=${data?.params?._page}&_sort=${data?.params?._sort}&_order=${data?.params?._order}`
    return API.post(
        `${URL.MEMBERSHIP_LIST_OLD}?_limit=${data?.params?._limit}&_page=${data?.params?._page}&_sort=${data?.params?._sort}&_order=${data?.params?._order}`,
        data.bodyData
    )
}
export const PlanListByMemberApi = (data) => {
    return API.post(`${URL.PLAN_LIST_BY_MEMBER}`, data.bodyData, {
        params: data?.params
    })
}

export const MembershipListExportToCsvApi = (data) =>
    axios.get(
        `https://time2enter-club-software-1024773831694.us-central1.run.app/api/v1/order/club-membership-plan/club/membership-order-listing-csv`,
        data.params
    )

//?club_id=129&age_type_name=youth
export const ChangeNewsLetterStatusApi = (ID,data) => API.post(`${URL.CHANGE_NEWS_LETTER_STATUS}/${ID}`, data)