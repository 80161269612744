import { useCallback, useEffect, useMemo, useRef } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'

const ClubClerkSidebarContent = () => {
    const { loading, admin } = useSelector((state) => state.Login)
    const ref = useRef()
    const activateParentDropdown = useCallback((item) => {
        item.classList.add('active')
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]

        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show')
        }

        if (parent) {
            parent.classList.add('mm-active') // li
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add('mm-show') // ul tag
                parent2.classList.add('mm-active') // li
                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add('mm-active') // li
                    parent3.classList.add('mm-active') // li
                    // parent3.childNodes[0].classList.add('mm-active') //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add('mm-show') // ul
                        parent4.classList.add('mm-active') // li
                        // parent4.childNodes[0].classList.add('mm-active')
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add('mm-show') // li
                            parent5.classList.add('mm-active') // li
                            // parent5.childNodes[0].classList.add('mm-active') // a tag
                            const parent6 = parent5.parentElement

                            if (parent6) {
                                parent6.classList.add('mm-show') // li
                                parent6.classList.add('mm-active') // li

                                // parent6.childNodes[0].classList.add('mm-active') // a tag
                                const parent7 = parent6.parentElement
                                if (parent7) {
                                    parent7.classList.add('mm-show') // li
                                    parent7.classList.add('mm-active') // li
                                    parent7.childNodes[0].classList.add('mm-active') // a tag
                                }
                            }
                        }
                    }
                }
            }
            scrollElement(item)
            return false
        }
        scrollElement(item)
        return false
    }, [])

    const removeActivation = (items) => {
        for (let i = 0; i < items.length; ++i) {
            const item = items[i]
            const parent = items[i].parentElement

            if (item && item.classList.contains('active')) {
                item.classList.remove('active')
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null
                if (parent2El && parent2El.id !== 'side-menu') {
                    parent2El.classList.remove('mm-show')
                }

                parent.classList.remove('mm-active')
                const parent2 = parent.parentElement

                if (parent2) {
                    parent2.classList.remove('mm-show')

                    const parent3 = parent2.parentElement
                    if (parent3) {
                        parent3.classList.remove('mm-active') // li
                        parent3.childNodes[0].classList.remove('mm-active')

                        const parent4 = parent3.parentElement // ul
                        if (parent4) {
                            parent4.classList.remove('mm-show') // ul
                            const parent5 = parent4.parentElement
                            if (parent5) {
                                parent5.classList.remove('mm-show') // li
                                parent5.childNodes[0].classList.remove('mm-active') // a tag
                                const parent6 = parent5.parentElement

                                if (parent6) {
                                    parent6.classList.remove('mm-show') // li
                                    parent6.childNodes[0].classList.remove('mm-active') // a tag
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
        let pathName = path.pathname.split('/')

        pathName = `/${pathName[1]}${pathName[2] ? `/${pathName[2]}` : ''}`
        let matchingMenuItem = null
        const ul = document.getElementById('side-menu')
        const items = ul.getElementsByTagName('a')

        removeActivation(items)

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
        ref.current.recalculate()
    }, [])

    useEffect(() => {
        new MetisMenu('#side-menu')
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        activeMenu()
    }, [activeMenu])

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }

    const menuList = [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <i className='bx bx-home-alt fs-box' />,
            children: []
        },
        {
            to: '/#',
            name: 'Setting',
            icon: <i className='bx bx-cog fs-box' />,
            children: [
                {
                    to: '/dashboard/setting',
                    name: 'Club Selection',
                    icon: <i className='bx bx bx-barcode' />,
                    children: []
                },
                {
                    to: '/dashboard/report-type',
                    name: 'Report Type',
                    icon: <i className='bx  bx-receipt' />,
                    children: []
                },
                {
                    to: '/dashboard/contest',
                    name: `Contest's`,
                    icon: <i className='bx bx bx-receipt' />,
                    children: []
                }
            ]
        },
        {
            to: '/dashboard/plans',
            name: 'Purchase Plan',
            icon: <i className='bx bx-money fs-box' />,
            children: []
        },
        {
            to: '/dashboard/club_plan_purchased',
            name: 'Club Purchased Plans',
            icon: <i className='bx bx-dollar-circle fs-box' />,
            children: []
        },
        {
            to: '/#',
            name: 'Membership',
            icon: <i className='bx bx-group fs-box' />,
            children: [
                {
                    to: '/dashboard/membership-id-configuration',
                    name: 'ID Configuration',
                    icon: <i className='bx bxs-id-card' />,
                    children: []
                },
                {
                    to: '/dashboard/age_classification',
                    name: 'Age Classification',
                    icon: <i className='bx bx-sitemap' />,
                    children: []
                },
                {
                    to: '/dashboard/membership_plans',
                    icon: <i className='bx bx-dollar' />,
                    name: 'Membership Plans',
                    children: []
                },
                {
                    to: '/dashboard/membership-list',
                    icon: <i className='bx bx-list-ol' />,
                    name: 'Membership List',
                    children: []
                },
                {
                    to: '/dashboard/letter_template',
                    icon: <i className='bx bx-file' />,
                    name: 'Letter Template',
                    children: []
                },
                // {
                //     to: '/dashboard/renew-letter-template',
                //     icon: <i className='bx bx-wallet' />,
                //     name: 'Renew Letter Template',
                //     children: []
                // },
                {
                    to: '/dashboard/membership_card_template',
                    icon: <i className='bx bx-news' />,
                    name: 'Membership Card Template',
                    children: []
                },
                {
                    to: '/dashboard/news-letter',
                    icon: <i className='bx bx-news' />,
                    name: 'Newsletter',
                    children: []
                },
                {
                    to: '/dashboard/processing_fees',
                    icon: <i className='bx bx-money' />,
                    name: 'Processing Fees',
                    children: []
                },
                {
                    to: '/dashboard/member_list',
                    icon: <i className='bx bx-group' />,
                    name: 'Member / Alias List',
                    children: []
                },
                {
                    to: '/#',
                    icon: <i className='bx bx-alarm' />,
                    name: 'Membership Reminder',
                    children: [
                        {
                            to: '/dashboard/membership-reminder-setup',
                            icon: '',
                            name: 'Reminder Setup',
                            children: []
                        },
                        {
                            to: '/dashboard/membership-reminder-template',
                            icon: '',
                            name: 'Reminder Template',
                            children: []
                        },
                        {
                            to: '/dashboard/membership-reminder-log',
                            icon: '',
                            name: 'Reminder Log',
                            children: []
                        }
                    ]
                }
            ]
        },
        {
            to: '/#',
            name: 'Sweepstakes',
            icon: <i className='bx bx-bar-chart-square fs-box' />,
            children: [
                {
                    to: '/#',
                    name: 'Sweepstakes Points',
                    icon: <i className='bx bx-highlight' />,
                    children: [
                        {
                            to: '/#',
                            name: 'Class',
                            icon: '',
                            children: [
                                {
                                    to: '/dashboard/sweepstake-class-non-national',
                                    name: 'Non-National',
                                    icon: '',
                                    children: []
                                },
                                {
                                    to: '/dashboard/sweepstake-class-national',
                                    name: 'National',
                                    icon: '',
                                    children: []
                                }
                            ]
                        },
                        {
                            to: '/#',
                            name: 'Fur',
                            icon: '',
                            children: [
                                {
                                    to: '/dashboard/sweepstake-fur-non-national',
                                    name: 'Non-National',
                                    icon: '',
                                    children: []
                                },
                                {
                                    to: '/dashboard/sweepstake-fur-national',
                                    name: 'National',
                                    icon: '',
                                    children: []
                                }
                            ]
                        },
                        {
                            to: '/#',
                            name: 'Variety Points',
                            icon: '',
                            children: [
                                {
                                    to: '/dashboard/sweepstake-variety-non-national',
                                    name: 'Non-National',
                                    icon: '',
                                    children: []
                                },
                                {
                                    to: '/dashboard/sweepstake-variety-national',
                                    name: 'National',
                                    icon: '',
                                    children: []
                                }
                            ]
                        },
                        {
                            to: '/#',
                            name: 'Group Points',
                            icon: '',
                            children: [
                                {
                                    to: '/dashboard/sweepstake-group-non-national',
                                    name: 'Non-National',
                                    icon: '',
                                    children: []
                                },
                                {
                                    to: '/dashboard/sweepstake-group-national',
                                    name: 'National',
                                    icon: '',
                                    children: []
                                }
                            ]
                        },
                        {
                            to: '/#',
                            name: 'Breed Points',
                            icon: '',
                            children: [
                                {
                                    to: '/dashboard/sweepstake-breed-non-national',
                                    name: 'Non-National',
                                    icon: '',
                                    children: []
                                },
                                {
                                    to: '/dashboard/sweepstake-breed-national',
                                    name: 'National',
                                    icon: '',
                                    children: []
                                }
                            ]
                        },
                        {
                            to: '/#',
                            name: 'Other Points',
                            icon: '',
                            children: [
                                {
                                    to: '/dashboard/sweepstake-other-non-national',
                                    name: 'Non-National',
                                    icon: '',
                                    children: []
                                },
                                {
                                    to: '/dashboard/sweepstake-other-national',
                                    name: 'National',
                                    icon: '',
                                    children: []
                                }
                            ]
                        }
                    ]
                },
                {
                    to: '/dashboard/show-setup',
                    name: 'Show Setup',
                    icon: <i className='bx bx-brightness' />,
                    children: []
                }
            ]
        },
        {
            to: '/#',
            name: 'Sanctions',
            icon: <i className='bx bx-credit-card fs-box' />,
            children: [
                {
                    to: '/dashboard/sanction-id-configuration',
                    name: 'ID Configuration',
                    icon: <i className='bx bxs-id-card' />,
                    children: []
                },
                {
                    to: '/dashboard/show-classification',
                    name: 'Show Classification',
                    icon: <i className='bx bx-sitemap' />,
                    children: []
                },
                {
                    to: '/dashboard/sanction-plans',
                    icon: <i className='bx bx-dollar' />,
                    name: 'Sanction Plans',
                    children: []
                },
                {
                    to: '/dashboard/sanction-list',
                    icon: <i className='bx bx-list-ol' />,
                    name: 'Sanction List',
                    children: []
                },
                {
                    to: '/dashboard/sanction-letter-template',
                    icon: <i className='bx bx-file' />,
                    name: 'Sanction Template',
                    children: []
                },
                // {
                //     to: '/dashboard/processing_fees',
                //     icon: <i className='bx bx-money' />,
                //     name: 'Processing Fees',
                //     children: []
                // },
                {
                    to: '/dashboard/sanction_processing_fees',
                    icon: <i className='bx bx-money' />,
                    name: 'Sanction Processing Fees',
                    children: []
                },
                {
                    to: '/#',
                    icon: <i className='bx bx-alarm' />,
                    name: 'Sanction Reminder',

                    children: [
                        {
                            to: '/dashboard/sanction-reminder-setup',
                            icon: '',
                            name: 'Reminder Setup',
                            children: []
                        },
                        {
                            to: '/dashboard/sanction-reminder-template',
                            icon: '',
                            name: 'Reminder Template',
                            children: []
                        },
                        {
                            to: '/dashboard/sanction-reminder-log',
                            icon: '',
                            name: 'Reminder Log',
                            children: []
                        }
                    ]
                }
            ]
        },
        {
            to: '/dashboard/clerk',
            name: 'Clerk',
            icon: <i className='fas fa-briefcase' />,
            children: []
        }
    ]
    const allRoutes = useMemo(() => {
        if (!loading && admin.role === 'club_clerk' && _.size(admin.assessMenuList)) {
            return filterMenuList(menuList, admin.assessMenuList)
        }
        return menuList
    }, [loading, admin.role, admin.assessMenuList])
    return (
        <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
            <div id='sidebar-menu'>
                <ul className='metismenu list-unstyled' id='side-menu'>
                    {allRoutes.map((menu, i) => {
                        return (
                            <li key={`${menu.to}-${i}`}>
                                <Link
                                    to={menu.to}
                                    className={classNames('waves-effect m-0', {
                                        'has-arrow': _.size(menu.children)
                                    })}
                                    title={menu.name}
                                >
                                    {menu?.icon}
                                    <span>{menu.name}</span>
                                </Link>
                                {_.size(menu.children) ? (
                                    <ul className='sub-menu'>
                                        {menu.children.map((level1, j) => {
                                            return (
                                                <li key={`${level1.to}-${j}`}>
                                                    <Link
                                                        to={level1.to}
                                                        // className='waves-effect m-0 gr-active'
                                                        className={classNames(
                                                            'waves-effect m-0 gr-active',
                                                            {
                                                                'has-arrow': _.size(level1.children)
                                                            }
                                                        )}
                                                    >
                                                        {' '}
                                                        {level1.icon}
                                                        <span>{level1.name}</span>
                                                    </Link>
                                                    {_.size(level1.children) ? (
                                                        <ul className='sub-menu'>
                                                            {level1.children.map((level2, k) => {
                                                                return (
                                                                    <li key={`${level2.to}-${k}`}>
                                                                        <Link
                                                                            to={level2.to}
                                                                            // className='waves-effect m-0 gr-active'
                                                                            className={classNames(
                                                                                'waves-effect m-0 gr-active',
                                                                                {
                                                                                    'has-arrow':
                                                                                        _.size(
                                                                                            level2.children
                                                                                        )
                                                                                }
                                                                            )}
                                                                        >
                                                                            {' '}
                                                                            {level2.icon}
                                                                            <span>
                                                                                {level2.name}
                                                                            </span>
                                                                        </Link>
                                                                        {_.size(level2.children) ? (
                                                                            <ul className='sub-menu'>
                                                                                {level2.children.map(
                                                                                    (level3, o) => {
                                                                                        return (
                                                                                            <li
                                                                                                key={`${level3.to}-${o}`}
                                                                                            >
                                                                                                <Link
                                                                                                    to={
                                                                                                        level3.to
                                                                                                    }
                                                                                                    className='waves-effect m-0 gr-active'
                                                                                                >
                                                                                                    {
                                                                                                        level3.icon
                                                                                                    }
                                                                                                    <span>
                                                                                                        {
                                                                                                            level3.name
                                                                                                        }
                                                                                                    </span>
                                                                                                </Link>
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                )}
                                                                            </ul>
                                                                        ) : null}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    ) : null}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </SimpleBar>
    )
}

export default ClubClerkSidebarContent

const filterMenuList = (menu, allowedLinks) => {
    return menu
        .map((item) => {
            // Check if the current item's `to` exists in the allowed links
            const isMatch = allowedLinks.includes(item.to)

            // Filter children recursively, if any
            const filteredChildren = item.children
                ? filterMenuList(item.children, allowedLinks)
                : []

            // If the current item matches or its children match, include it
            if (isMatch || filteredChildren.length > 0) {
                return {
                    ...item,
                    children: filteredChildren.length > 0 ? filteredChildren : undefined
                }
            }

            // Exclude items that don't match and have no matching children
            return null
        })
        .filter(Boolean) // Remove null values
}