import { createSlice } from '@reduxjs/toolkit'
import {
    MemberListRevenueBreakDown,
    MembershipRevenueBreakDown,
    PlanListRevenueBreakDown,
    RevenueBreakDownGrandTotal
} from './thunk'

export const initialState = {
    list: [],
    members: {
        list: [],
        loading: true
    },
    plans: {
        list: [],
        loading: true
    },
    grandTotal: {
        grand_total: '0.00',
        connected_accnt_amt: '0.00',
        master_accnt_amt: '0.00',
        loading: true
    },
    loading: true
}
const MemberShipRevenueBreakDownReducer = createSlice({
    name: '@membership/RevenueBreakDown',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MembershipRevenueBreakDown.pending, (state) => {
            state.list = []
            state.loading = true
        })
        builder.addCase(MembershipRevenueBreakDown.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data || []
            state.loading = false
        })
        builder.addCase(MembershipRevenueBreakDown.rejected, (state) => {
            state.list = []
            state.loading = false
        })

        // MemberListRevenueBreakDown
        builder.addCase(MemberListRevenueBreakDown.pending, (state) => {
            state.members.list = []
            state.members.loading = true
        })
        builder.addCase(MemberListRevenueBreakDown.fulfilled, (state, action) => {
            state.members.list = action.payload || []
            state.members.loading = false
        })
        builder.addCase(MemberListRevenueBreakDown.rejected, (state) => {
            state.members.list = []
            state.members.loading = false
        })

        // MemberListRevenueBreakDown
        builder.addCase(PlanListRevenueBreakDown.pending, (state) => {
            state.plans.list = []
            state.plans.loading = true
        })
        builder.addCase(PlanListRevenueBreakDown.fulfilled, (state, action) => {
            state.plans.list = action.payload || []
            state.plans.loading = false
        })
        builder.addCase(PlanListRevenueBreakDown.rejected, (state) => {
            state.plans.list = []
            state.plans.loading = false
        })

        // RevenueBreakDownGrandTotal
        builder.addCase(RevenueBreakDownGrandTotal.pending, (state) => {
            state.grandTotal.master_accnt_amt = '0.00'
            state.grandTotal.grand_total = '0.00'
            state.grandTotal.connected_accnt_amt = '0.00'
            state.grandTotal.loading = true
        })
        builder.addCase(RevenueBreakDownGrandTotal.fulfilled, (state, { payload }) => {
            state.grandTotal.master_accnt_amt = payload.master_accnt_amt
            state.grandTotal.grand_total = payload.grand_total
            state.grandTotal.connected_accnt_amt = payload.connected_accnt_amt
            state.grandTotal.loading = false
        })
        builder.addCase(RevenueBreakDownGrandTotal.rejected, (state) => {
            state.users.list = []
            state.grandTotal.loading = false
        })
    }
})
export default MemberShipRevenueBreakDownReducer.reducer