import { createSlice } from '@reduxjs/toolkit'

import {
    MembershipPlansByIdForMember,
    MemberShipLists,
    MemberShipListOld,
    SubMemberMemberList,
    PlanListByMember
} from './thunk'
import _ from 'lodash'
import moment from 'moment'

// export const memberListForm = {
//     member_id: '',
//     arba_number: '',
//     is_minor: false,
//     date_of_birth: ''
// }

export const membershipForm = {
    member_id: '',
    club_id: '',
    membership_id: '',
    membership_plan_id: '',
    member_list: [
        {
            member_id: '',
            arba_number: '',
            is_minor: false,
            age_classification_id: '',
            age_type_name: '',
            date_of_birth: ''
        }
    ],
    amount: ''
}

export const initialState = {
    loading: true,
    form: membershipForm,
    memberListLoading: false,
    membershipPlanDetail: {},
    list: [],
    subMemberMembershipData: {
        list: [],
        loading: true
    },
    planListByMember: {
        list: [],
        loading: true
    }
}
const MemberShipList = createSlice({
    name: 'Membership/List',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MembershipPlansByIdForMember.pending, (state) => {
            state.membershipPlanDetail = {}
            state.memberListLoading = true
        })
        builder.addCase(MembershipPlansByIdForMember.fulfilled, (state, action) => {
            state.membershipPlanDetail = action.payload
            state.memberListLoading = false
        })
        builder.addCase(MembershipPlansByIdForMember.rejected, (state) => {
            state.membershipPlanDetail = {}
            state.memberListLoading = false
        })
        builder.addCase(MemberShipLists.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberShipLists.fulfilled, (state, action) => {
            const { payload } = action
            state.list = payload || []
            state.loading = false
        })
        builder.addCase(MemberShipLists.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(MemberShipListOld.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberShipListOld.fulfilled, (state, action) => {
            const { payload } = action
            state.list = payload || []
            state.loading = false
        })
        builder.addCase(MemberShipListOld.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SubMemberMemberList.pending, (state) => {
            state.subMemberMembershipData.list = []
            state.subMemberMembershipData.loading = true
        })
        builder.addCase(SubMemberMemberList.fulfilled, (state, action) => {
            const { payload } = action
            state.subMemberMembershipData.list = payload || []
            state.subMemberMembershipData.loading = false
        })
        builder.addCase(SubMemberMemberList.rejected, (state) => {
            state.subMemberMembershipData.list = []
            state.subMemberMembershipData.loading = false
        })
        builder.addCase(PlanListByMember.pending, (state) => {
            state.planListByMember.list = []
            state.planListByMember.loading = true
        })
        builder.addCase(PlanListByMember.fulfilled, (state, action) => {
            const { payload } = action
            state.planListByMember.list = _.map(payload || [], (o) => {
                return {
                    ...o,
                    isPlanExpired: o?.expires_on
                        ? moment(moment(o?.expires_on).format('YYYY-MM-DD')).isBefore(
                              moment().format('YYYY-MM-DD')
                          )
                        : false
                }
            })
            state.planListByMember.loading = false
        })
        builder.addCase(PlanListByMember.rejected, (state) => {
            state.planListByMember.list = []
            state.planListByMember.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberShipList.reducer
