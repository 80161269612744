import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MembershipTypeListApi,
    MemberUserListApi,
    ClubListApi,
    MembershipPlansByIdForMemberApi,
    MemberShipListApi,
    MembershipListExportToCsvApi,
    MemberShipListOldApi,
    SubMemberMemberShipListApi,
    PlanListByMemberApi,
    ChangeNewsLetterStatusApi
} from './MembershipList.services'
import { paginationData } from '../Pagination/reducers'
import { toast } from 'react-toastify'
import _ from 'lodash'
// import { paginationData } from '../Pagination/reducers'

//services
export const ClubList = createAsyncThunk(
    'MembershipList/ClubList',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubListApi()
            // const res2 = await MembershipTypeListApi(data)
            // const res3 = await MemberUserListApi(data.id, data)
            // console.log('ClubList---res',res,res2,res3)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipTypeList = createAsyncThunk(
    'MembershipList/TypeList',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipTypeListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const MemberUserList = createAsyncThunk(
    'MembershipList/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberUserListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MembershipPlansByIdForMember = createAsyncThunk(
    'Membership/plan/detail',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPlansByIdForMemberApi(data)
            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberShipLists = createAsyncThunk(
    'Membership/list/club',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await MemberShipListApi(data)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res.data
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            toast.error(err.response.data)
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberShipListOld = createAsyncThunk(
    'Membership/list/club/old',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await MemberShipListOldApi(data)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res.data
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            toast.error(err.response.data)
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const PlanListByMember = createAsyncThunk(
    'Plan/list/by/member',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await PlanListByMemberApi(data)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data.params._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res.data
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            toast.error(err.response.data)
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const MembershipListExportToCsv = createAsyncThunk(
    'Membership/export/csv',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipListExportToCsvApi(data)
            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SubMemberMemberList = createAsyncThunk(
    'Membership/SubMember/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SubMemberMemberShipListApi(data)

            const finalRes = _.filter(res.data, (item) => item.user_data.user_role === 'subuser')

            return finalRes
        } catch (err) {
            toast.error(err.response.data)
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)


export const ChangeNewsLetterStatus = createAsyncThunk(
    'Membership/NewsLetter/Status',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ChangeNewsLetterStatusApi(data.body.club_id, data.body)
            return res.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)